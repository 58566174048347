import { Coordinate } from "../type/gameType";

export function areEqual(arr1: any[], arr2: any[]) {
    if (arr1.length === arr2.length) {
        return arr1.every(element => {
            if (arr2.includes(element)) {
                return true;
            }
            return false;
        });
    }
    return false;
}

export function checkPointExistInListOfPoint(targetPoint: Coordinate, points: Coordinate[]): boolean {
    const duplicatedCoordinates = points.findIndex((point: Coordinate) => {
        return (point.x === targetPoint.x && point.y === targetPoint.y)
    })

    return duplicatedCoordinates !== -1
}


export function createRandomCoordinate(row: number, column: number, exceptionalArray?: Coordinate[]): Coordinate {
    const x = (Math.floor(Math.random() * row))
    const y = Math.floor((Math.random() * column))

    if (!exceptionalArray) return { x, y }

    return checkPointExistInListOfPoint({ x, y }, exceptionalArray) ? createRandomCoordinate(row, column, exceptionalArray) : { x, y }
}

export function deterioratingFunction(maximum: number, initial: number, rate: number, x: number) {
    return maximum - (maximum - initial) * Math.exp(-rate * x)
}

export function exponentialFunction(A: number, rate: number, x: number) {
    return A * Math.exp(rate * x)
}