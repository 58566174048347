import { difficultyLevel } from "../../config/gameConfig";
import { Coordinate, Level } from "../../type/gameType"

export function areEqual(arr1: any[], arr2: any[]) {
    if (arr1.length === arr2.length) {
        return arr1.every(element => {
            if (arr2.includes(element)) {
                return true;
            }
            return false;
        });
    }
    return false;
}

export function createZerothMatrix(row: number, column: number): Array<number>[] {
    return Array(row).fill(Array(column).fill(0))
}

export function createRandomMatrix(row: number, column: number): Array<number>[] {
    return Array(row).fill(Array(column).fill(Math.random().toFixed()))
}

export function createRandomCoordinate(row: number, column: number): Coordinate {
    return {
        x: (Math.floor(Math.random() * row)), y: Math.floor((Math.random() * column))
    }
}

export function checkPointExistInListOfPoint(targetPoint: Coordinate, points: Coordinate[]): boolean {
    const duplicatedCoordinates = points.filter((point: Coordinate) => {
        return (point.x === targetPoint.x && point.y === targetPoint.y)
    })

    return duplicatedCoordinates[0] ? true : false
}

export function calculateNumberOfToxicFood(row: number, column: number, difficulty: Level) {
    const numberOfToxicFood = Math.floor(row * column * (1 / difficultyLevel[difficulty]))
    return numberOfToxicFood === 0 ? 1 : numberOfToxicFood
}

