import React from 'react';
import { Button } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface GameStartButtonProps {
    className?: string
    onClick: () => void
}

function ResetConfigButton({ className, onClick }: GameStartButtonProps) {

    return <div className={className}>
        <Button
            color="error"
            variant="outlined"
            onClick={onClick}>
            <RestartAltIcon />
        </Button >
    </div>;
}

export default ResetConfigButton;
