import React from 'react';
import { Stack } from '@mui/material';
import ConfigPlatform from './Platform/ConfigPlatform';
import GamePlatform from './Platform/GamePlatform';
import GameStatusPlatform from './Platform/GameStatusPlatform';
import PlayerStatusPlatform from './Platform/PlayerStatusPlatform';
import { useDispatch, useSelector } from 'react-redux';
import { useGameConfigSlice } from '../../redux/configReducer/reducer';
import { makeSelectorGameIsStart } from '../../redux/gameReducer/selectors';
import { useStyleTheme } from '../provides/StyleThemeProvider/StyleThemeProvider';

function CustomGame() {
    const { setIsTransparentMode } = useStyleTheme()

    const dispatch = useDispatch()
    const { actions: gameConfigActions } = useGameConfigSlice()

    const gameIsStart = useSelector(makeSelectorGameIsStart())

    React.useEffect(() => {
        setIsTransparentMode(false)
        dispatch(gameConfigActions.switchGameMode({ gameMode: "custom" }))
        return () => {
            dispatch(gameConfigActions.resetConfig())
        }
    }, [])


    return <>
        <PlayerStatusPlatform />
        <GamePlatform>
            <GameStatusPlatform />
        </GamePlatform>
        <Stack direction="row" justifyContent="center" >
            {!gameIsStart && <ConfigPlatform />}
        </Stack>
    </>;
}

export default CustomGame;
