import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectorGameIsPause, makeSelectorGameIsStart } from '../../../redux/gameReducer/selectors';
import { useSnakeGame } from '../../context/SnakeGameContext';
import ClearIcon from '@mui/icons-material/Clear';
import useKeyPress from '../../../hook/useKeyPress';
import { useConfirm } from 'material-ui-confirm';

interface EndGameButtonPros {
    className?: string
}

function EndGameButton({ className }: EndGameButtonPros) {
    const confirm = useConfirm();

    const { changeGameIsEndState, gamePlayPause } = useSnakeGame()
    const { keyPressed: isESCKeyPress } = useKeyPress("Escape", true)

    const gameIsPause = useSelector(makeSelectorGameIsPause())

    const handleClick = () => {
        if (!gameIsPause) {
            gamePlayPause()
        }
        confirm({ description: 'Do you want to End the Game?' })
            .then(() => { changeGameIsEndState(true) })
    };


    React.useEffect(() => {
        if (isESCKeyPress) {
            handleClick()
        }
    }, [isESCKeyPress])

    return <div className={className}>
        <Button
            variant="outlined"
            color="error"
            onClick={handleClick}>
            <ClearIcon />
        </Button >
    </div>
}

export default EndGameButton;
