import { Button } from '@mui/material';
import React from 'react';
import { globalTheme } from '../../../config/generalStyle';
import { useSnakeGame } from '../../context/SnakeGameContext';

interface GameOverButtonProps {
    className?: string
}

function GameOverButton({ className }: GameOverButtonProps) {
    const { gameInit } = useSnakeGame()

    return <div className={className}>
        <Button
            sx={{ color: globalTheme.buttonText.color }}
            variant="contained"
            color="error"
            onClick={gameInit}>
            Game Over (Space)
        </Button >
    </div>;
}

export default GameOverButton;
