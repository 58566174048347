import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../type/RootState";
import { initialState } from "./reducer";

const selectSlice = (state: RootState) => state.gameConfig || initialState

export const selectGameConfig = createSelector([selectSlice], (state) => state)

export const makeSelectorSquareWidth = () => createSelector(selectGameConfig, (state) => state.squareWidth)
export const makeSelectorRow = () => createSelector(selectGameConfig, (state) => state.row)
export const makeSelectorColumn = () => createSelector(selectGameConfig, (state) => state.column)
export const makeSelectorCanvasHeight = () => createSelector(selectGameConfig, (state) => state.canvasHeight)
export const makeSelectorCanvasWidth = () => createSelector(selectGameConfig, (state) => state.canvasWidth)
export const makeSelectorCanvasWidthOffset = () => createSelector(selectGameConfig, (state) => state.canvasWidthOffset)
export const makeSelectorCanvasHeightOffset = () => createSelector(selectGameConfig, (state) => state.canvasHeightOffset)
export const makeSelectorDifficulty = () => createSelector(selectGameConfig, (state) => state.difficulty)
export const makeSelectorGameMode = () => createSelector(selectGameConfig, (state) => state.gameMode)
