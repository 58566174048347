import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { difficultyColor, difficultyLevel, difficultyStar } from '../../../config/gameConfig';
import { useGameConfigSlice } from '../../../redux/configReducer/reducer';
import { makeSelectorDifficulty } from '../../../redux/configReducer/selectors';
import { Level } from '../../../type/gameType';
import { capitalizeFirstLetter } from '../../../utils/letter';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { globalTheme } from '../../../config/generalStyle';

interface DifficultySelectPlatformProps {
    className?: string,
    disabled?: boolean
}

function DifficultySelectPlatform({ className, disabled }: DifficultySelectPlatformProps) {
    const dispatch = useDispatch()
    const { actions: gameConfigActions } = useGameConfigSlice()

    const difficulty = useSelector(makeSelectorDifficulty())

    const handleChangeDifficulty = (difficulty: Level) => {
        dispatch(gameConfigActions.updateDifficulty({ difficulty }))
    }

    return <StyledSelectContainer className={className}>
        <FormControl fullWidth>
            <StyledSelect
                variant="outlined"
                value={difficulty}
                onChange={(e: any) => { handleChangeDifficulty(e.target.value) }}
                disabled={disabled || false}
            >
                {Object.keys(difficultyLevel).filter(level => (level === "easy" || level === "difficult" || level === "extremely")).map(level => {
                    return < MenuItem key={level} value={level} >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            {capitalizeFirstLetter(level)}
                            <Stack direction="row" >
                                {Array(difficultyStar[level as Level]).fill(0).map((item, index) => {
                                    return <StarIcon key={index} />
                                })}
                                {Array(difficultyStar.nightmare - difficultyStar[level as Level]).fill(0).map((item, index) => {
                                    return <StarBorderIcon key={index} />
                                })}
                            </Stack>
                        </Stack>
                    </MenuItem>
                })}
            </StyledSelect>
        </FormControl>
    </StyledSelectContainer >
}

export default DifficultySelectPlatform;

const StyledSelectContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`

const StyledSelect = styled(Select) <{ value: Level }>`
    .MuiSelect-outlined{
        background-color:${props => difficultyColor[props.value]};
        color: ${globalTheme.text.color};
    }
`