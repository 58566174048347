import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { makeSelectorGameIsStart } from '../../../redux/gameReducer/selectors';
import ControlPanel from '../../components/ControlPanel/ControlPanel';

function ConfigPlatform() {
    const gameIsStart = useSelector(makeSelectorGameIsStart())

    return <>
        <StyledConfigContainer>
            <ControlPanel className="margin" disabled={gameIsStart} />
        </StyledConfigContainer>
    </>;
}

export default ConfigPlatform;

const StyledConfigContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;

    .margin{
        margin:2px;
    }
`