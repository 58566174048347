import { Coordinate, Direction } from "../type/gameType"
import { checkPointExistInListOfPoint, createRandomCoordinate, deterioratingFunction, exponentialFunction } from "./math"

export function calculateNextSnakePosition(snakeArray: Coordinate[], movingDirection: Direction, row: number, column: number): Coordinate[] {
    const nextSnakePosition = [...snakeArray]

    snakeArray.reduce((prev, curr, index) => {
        const { x, y } = curr
        if (index === 0) {
            switch (movingDirection) {
                case Direction.DOWN: {
                    nextSnakePosition[index] = { x, y: (y + 1) % column }
                    break
                }
                case Direction.UP: {
                    nextSnakePosition[index] = { x, y: (y + column - 1) % column }
                    break
                }
                case Direction.RIGHT: {
                    nextSnakePosition[index] = { x: (x + 1) % row, y }
                    break
                }
                case Direction.LEFT: {
                    nextSnakePosition[index] = { x: (x + row - 1) % row, y }
                    break
                }
            }
        } else {
            nextSnakePosition[index] = prev
        }
        return curr
    }, { x: 0, y: 0 })
    return nextSnakePosition
}

export function calculateNextSnakeHead(snakeArray: Coordinate[], movingDirection: Direction, row: number, column: number): Coordinate {
    const { x, y } = snakeArray[0]
    switch (movingDirection) {
        case Direction.DOWN: {
            return { x, y: (y + 1) % column }
        }
        case Direction.UP: {
            return { x, y: (y + column - 1) % column }
        }
        case Direction.RIGHT: {
            return { x: (x + 1) % row, y }
        }
        case Direction.LEFT: {
            return { x: (x + row - 1) % row, y }
        }
    }
}

export function createWallPosition(wallThickness: number, row: number, column: number) {
    const wallArray = []

    for (let x = 0; x < row; x++) {
        for (let y = 0; y < column; y++) {
            if (x < wallThickness || x > row - wallThickness - 1 || y < wallThickness || y > column - wallThickness - 1) {
                wallArray.push({ x, y })
            }
        }
    }
    return wallArray
}

export function createNewFoodPosition(snake: Coordinate[], blockerArr: Coordinate[], row: number, column: number) {
    return createRandomCoordinate(row, column, [...snake, ...blockerArr])
}

export function checkEndGame(newSnake: Coordinate[], blockerArr: Coordinate[]): boolean {
    // hit snake body or hit barriers
    return checkPointExistInListOfPoint(newSnake[0], newSnake.slice(1)) || checkPointExistInListOfPoint(newSnake[0], blockerArr)
}

export function checkFoodIsEaten(newSnake: Coordinate[], foodArray: Coordinate[]): boolean {
    return checkPointExistInListOfPoint(newSnake[0], foodArray)
}


export function calculateFinalGameSpeed(gamePlayerLevel: number, currentFramerate: number): number {
    return currentFramerate + 3 * (gamePlayerLevel - 1)
}

export function calculateClassModeGameScorePerFood(gamePlayerScore: number, currentLevel: number, currentTime: number): number {
    const coefficient = 775
    const scoreUpRate = 0.25
    const levelScoreAdd = Math.floor(exponentialFunction(coefficient, scoreUpRate, currentLevel))

    const scoreDropRate = 0.000005
    const initialScoreDrop = 0
    const timeScoreReduce = Math.floor(deterioratingFunction(levelScoreAdd / 2, initialScoreDrop, scoreDropRate, currentTime))

    return gamePlayerScore + levelScoreAdd - timeScoreReduce
}

export function calculateLevelUnderCertainNumberOfFood(currentNumberOfFood: number): number {
    const maximumLevel = 11
    const initialLevel = 1
    const levelUpRate = 0.05
    return Math.floor(deterioratingFunction(maximumLevel, initialLevel, levelUpRate, currentNumberOfFood))
}


/**
 * score	level	level
0	1	1
1	1.438935179	1
2	1.856463238	1
3	2.253628212	2
4	2.631423222	2
5	2.990792952	2
6	3.332636014	3
7	3.657807193	3
8	3.967119586	3
9	4.261346635	4
10	4.541224063	4
11	4.807451707	4
12	5.060695275	5
13	5.301588009	5
14	5.530732266	5
15	5.748701025	5
16	5.956039323	5
17	6.153265612	6
18	6.340873062	6
19	6.519330789	6
20	6.689085029	6
21	6.850560258	6
22	7.004160247	7
 */

export function calculateLevelUnderCertainTime(currentTime: number): number {
    const maximumLevel = 30
    const initialLevel = 1
    const levelUpRate = 0.00001
    return Math.floor(deterioratingFunction(maximumLevel, initialLevel, levelUpRate, currentTime))
}