import React, { ReactChild } from 'react';
import styled from 'styled-components';
import { useRouteContext } from '../../provides/RouteProvider/RouteProvider';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import { globalTheme } from '../../../config/generalStyle';

const headerBackgroundColor: { [key: string]: string } = {
    "/custom": "#000000",
    "/classic": "#00000000",
    "/survival": "#00000000",
    "/leaderboard": "#000000",
    "/": globalTheme.headerBackground.color,
}

const AppHeader = () => {
    const history = useHistory()
    const { currentPath, changePath } = useRouteContext()

    return <StyledAppHeaderContainer background={headerBackgroundColor[currentPath]}>
        <Button
            className="button-style"
            style={{ cursor: "pointer", padding: 12 }}
            onClick={() => {
                changePath("/")
                history.push("/")
            }}>
            {currentPath === "/" ? <HomeIcon /> : <ArrowBackIcon />}
        </Button >
        {currentPath === "/" && <Button
            className="button-style"
            style={{ cursor: "pointer", padding: 12 }}
            onClick={() => {
                changePath("/leaderboard")
                history.push("/leaderboard")
            }}>
            <MilitaryTechIcon />Leaderboard
        </Button >}
    </StyledAppHeaderContainer>
}



const StyledAppHeaderContainer = styled.div<{ background: string }>`
    background-color:${props => props.background};
    padding:0px 0px;
    
    .button-style{
        color:${globalTheme.text.color};
    }
`

interface AppLayoutProps {
    children: ReactChild
}

const routeBackgroundImage: { [key: string]: string } = {
    "/custom": "#8a8a8a",
    "/classic": "url('./background-image/background2.png')",
    "/survival": "url('./background-image/background3.png')",
    "/leaderboard": "#000000",
    "/": "#191919",
}

function AppLayout({ children }: AppLayoutProps) {
    const currentPath = useRouteContext().currentPath

    return <StyledAppContainer background={routeBackgroundImage[currentPath]}>
        <AppHeader />
        {children}
    </StyledAppContainer>;
}

export default AppLayout;

const StyledAppContainer = styled.div<{ background: string }>`
  background: ${props => props.background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`