// level === "easy" || level === "difficult" || level === "extremely"

import { GameMode, GameResult } from "../type/gameType"

export const difficultyLevel = {
    easy: 1000,
    medium: 500,
    difficult: 200,
    extremely: 100,
    nightmare: 50,
    hell: 10
}

export const survivalToxicCreationWithDifficultyLevel = {
    easy: 1,
    medium: 3,
    difficult: 5,
    extremely: 7,
    nightmare: 10,
    hell: 15
}

export const difficultyStar = {
    easy: 1,
    medium: 2,
    difficult: 4,
    extremely: 6,
    nightmare: 8,
    hell: 10
}

export const difficultyColor = {
    easy: "#5DCF20",
    medium: "#1CB6A8",
    difficult: "#18209F",
    extremely: "#9F1818",
    nightmare: "#9715A4",
    hell: "#0F0210"
}

export const classicMode = {
    row: 100,
    column: 100,
    framerate: 15
}

export const gameModeConfig = {
    classicMode: classicMode
}

export const gameModeQuality = {
    classic: "score",
    survival: "duration"
}

export const gameModeRankingQualityConvertor = (gameMode: GameMode, gameResult: GameResult) => {
    switch (gameMode) {
        case "classic": {
            return gameResult.score
        }
        case "survival": {
            return ((gameResult.duration / 1000).toFixed()) + "s"
        }
    }
}