import React, { ReactChild } from 'react'
import styled from "styled-components"

interface AbsoluteCenterProps {
    children: ReactChild,
    hidden?: boolean
}

function AbsoluteCenter({ children, hidden }: AbsoluteCenterProps) {
    return (
        <StyledAbsoluteCenterContainer hidden={hidden || false}>
            {children}
        </StyledAbsoluteCenterContainer>
    )
}

export default AbsoluteCenter


const StyledAbsoluteCenterContainer = styled.div<{ hidden: boolean }>`
    visibility:${props => props.hidden ? "hidden" : "visible"};
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:0;
`