import React from 'react';
import TextField from '@mui/material/TextField';

interface InputTextProps {
    label: string,
    className?: string
    handleTextOnChange: (e: any) => void | "error"
    value?: any
    defaultValue?: any
    type?: string
}
function InputText({ label, className, handleTextOnChange, value, defaultValue, type }: InputTextProps) {
    const [error, setError] = React.useState<boolean>(false)

    function handleOnChange(e: any) {
        if (handleTextOnChange(e)) {
            setError(true)
        } else {
            setError(false)
        }
    }

    return <div className={className}>
        <TextField error={error} type={type} defaultValue={defaultValue} value={value} label={label} id="custom-css-outlined-input" onChange={handleOnChange} />
    </div>;
}

export default InputText;