import * as React from 'react'

export interface ResponsiveContextValue {
  isMobileLayout: boolean
}

export const ResponsiveContext = React.createContext<ResponsiveContextValue>({
  isMobileLayout: true,
})

export const useResponsive = () => React.useContext(ResponsiveContext)


const isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  )
}

export interface ResponsiveProviderProps { }

export const ResponsiveProvider = ({
  children,
}: React.PropsWithChildren<ResponsiveProviderProps>) => {
  const isMobileLayout = isTouchDevice()

  return (
    <ResponsiveContext.Provider value={{ isMobileLayout }}>
      {children}
    </ResponsiveContext.Provider>
  )
}
