export type Level = "easy" | "medium" | "difficult" | "extremely" | "nightmare" | "hell"

export type GameMode = "classic" | "custom" | "survival"

export interface Coordinate {
    x: number,
    y: number
}


export enum Direction {
    "UP",
    "DOWN",
    "LEFT",
    "RIGHT"
}

export interface GamePlayerResult {
    name: string,
    gameResult: GameResult
}

export interface GameResult {
    duration: number,
    food: number,
    level: number,
    score: number
}