export const snakeLevelColor = [
    "#FF0000",
    "#FFA200",
    "#FFF300",
    "#B2FF00",
    "#59FF00",
    "#00FFA6",
    "#00FFFF",
    "#009BFF",
    "#003EFF",
    "#B900FF",
    "#4E0A4C"
]

export const lightTheme = {
    snake: {
        fillColor: snakeLevelColor,
        strokeColor: "#00000030"
    },
    food: {
        fillColor: "#E57373",
        strokeColor: "#00000030"
    },
    background: {
        fillColor: (isTransparent: boolean) => {
            return isTransparent ? "#F6F6F690" : "#F6F6F6"
        },
        strokeColor: "#00000030"
    },
    wall: {
        fillColor: "#000000",
        strokeColor: "#00000030"
    },
    toxicFood: {
        fillColor: "#999999",
        strokeColor: "#00000030"
    },
}

export const darkTheme = {
    snake: {
        fillColor: snakeLevelColor,
        strokeColor: "#00000030"
    },
    food: {
        fillColor: "#E57373",
        strokeColor: "#00000030"
    },
    background: {
        fillColor: (isTransparent: boolean) => {
            return isTransparent ? "#33333390" : "#333333"
        },
        strokeColor: "#00000030"
    },
    wall: {
        fillColor: "#000000",
        strokeColor: "#00000030"
    },
    toxicFood: {
        fillColor: "#999999",
        strokeColor: "#00000030"
    },
}
