import { Stack } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGameConfigSlice } from '../../redux/configReducer/reducer';
import { useSnakeGameSlice } from '../../redux/gameReducer/reducer';
import { makeSelectorGameIsStart } from '../../redux/gameReducer/selectors';
import { useStyleTheme } from '../provides/StyleThemeProvider/StyleThemeProvider';
import DifficultySelectPlatform from './Platform/DifficultySelectPlatform';
import GamePlatform from './Platform/GamePlatform';
import GameStatusPlatform from './Platform/GameStatusPlatform';
import PlayerStatusPlatform from './Platform/PlayerStatusPlatform';

function ClassicGame() {
    const { setIsTransparentMode } = useStyleTheme()
    const dispatch = useDispatch()
    const { actions: snakeGameActions } = useSnakeGameSlice()
    const { actions: gameConfigActions } = useGameConfigSlice()

    const gameIsStart = useSelector(makeSelectorGameIsStart())

    React.useEffect(() => {
        setIsTransparentMode(true)
        dispatch(gameConfigActions.switchGameMode({ gameMode: "classic" }))
        dispatch(gameConfigActions.updateRowColumn({
            row: 30, column: 30
        }))
        dispatch(snakeGameActions.updateFramerate({
            framerate: 15
        }))
        return () => {
            dispatch(gameConfigActions.resetConfig())
        }
    }, [])

    return <>
        <PlayerStatusPlatform />
        <GamePlatform>
            <GameStatusPlatform />
        </GamePlatform>
        <StyledDifficultySelectPlatform disabled={gameIsStart} className={gameIsStart ? "is-start" : ""} />
    </>;
}

export default ClassicGame;


const StyledDifficultySelectPlatform = styled(DifficultySelectPlatform)`
    position:absolute;
    bottom:0;

    &.is-start{
        opacity:0.3
    }
`