import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../type/RootState";
import { initialState } from "./reducer";

const selectSlice = (state: RootState) => state.snakeGame || initialState

export const selectSnakeGame = createSelector([selectSlice], (state) => state)

export const makeSelectorFoodPosition = () => createSelector(selectSnakeGame, (state) => state.foodPosition)
export const makeSelectorFramerate = () => createSelector(selectSnakeGame, (state) => state.framerate)
export const makeSelectorSnakeDirection = () => createSelector(selectSnakeGame, (state) => state.snakeDirection)
export const makeSelectorSnakePosition = () => createSelector(selectSnakeGame, (state) => state.snakePosition)
export const makeSelectorToxicFoodPosition = () => createSelector(selectSnakeGame, (state) => state.toxicFoodPosition)
export const makeSelectorWallPosition = () => createSelector(selectSnakeGame, (state) => state.wallPosition)
export const makeSelectorClearPosition = () => createSelector(selectSnakeGame, (state) => state.clearPosition)
export const makeSelectorGameIsEnd = () => createSelector(selectSnakeGame, (state) => state.gameIsEnd)
export const makeSelectorGameIsPause = () => createSelector(selectSnakeGame, (state) => state.gameIsPause)
export const makeSelectorGameIsStart = () => createSelector(selectSnakeGame, (state) => state.gameIsStart)
export const makeSelectorGameDuration = () => createSelector(selectSnakeGame, (state) => state.gameDuration)
export const makeSelectorGamePlayerLevel = () => createSelector(selectSnakeGame, (state) => state.gamePlayerLevel)
export const makeSelectorGamePlayerFood = () => createSelector(selectSnakeGame, (state) => state.gamePlayerFood)
export const makeSelectorGamePlayerScore = () => createSelector(selectSnakeGame, (state) => state.gamePlayerScore)