import React from 'react';

function useKeyPress(targetKey: string, needUpHandler: boolean = false) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = React.useState<boolean>(false);

    // If pressed key is our target key then set to true
    const downHandler = React.useCallback(({ key }: KeyboardEvent) => {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }, [targetKey])

    // If released key is our target key then set to false
    const upHandler = React.useCallback(({ key }: KeyboardEvent) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    }, [targetKey]);

    // Add event listeners
    React.useEffect(() => {
        document.addEventListener("keydown", downHandler);
        if (needUpHandler) {
            document.addEventListener("keyup", upHandler);
        }
        // Remove event listeners on cleanup
        return () => {
            document.removeEventListener("keydown", downHandler);
            if (needUpHandler) {
                document.removeEventListener("keyup", upHandler);
            }
        };
    }, [downHandler, upHandler, needUpHandler]); // Empty array ensures that effect is only run on mount and unmount
    return { keyPressed, setKeyPressed };
}

export default useKeyPress;
