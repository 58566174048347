import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { snakeGameRankingService } from "../../../Services/SnakeGameRankingService";
import {
  GameMode,
  GamePlayerResult,
  GameResult,
  Level,
} from "../../../type/gameType";

interface RankFormProps {
  gameMode: GameMode;
  rank: number;
  gameResult: GameResult;
  rankList: GamePlayerResult[];
  handleRankOnListFormModalClose: () => void;
  difficulty: Level;
}

function RankForm({
  gameMode,
  rank,
  gameResult,
  rankList,
  handleRankOnListFormModalClose,
  difficulty,
}: RankFormProps) {
  const [name, setName] = React.useState<string>("");

  async function handleSubmit() {
    if (!name) return;
    await snakeGameRankingService.updateNewRankList(
      gameMode,
      rank,
      rankList,
      name,
      gameResult,
      difficulty
    );
    handleRankOnListFormModalClose();
  }

  return (
    <StyledFormContainer>
      <Stack direction="column" spacing={3}>
        <div className="congratulation-text">😆Congratulation😆</div>
        <div className="rank-text">
          You are no.{rank} in {gameMode} Mode !!
        </div>
        <TextField
          value={name}
          sx={{ input: { color: "white" } }}
          id="standard-search"
          label="name"
          type="search"
          variant="standard"
          color="info"
          onChange={(e) => setName(e.target.value)}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </StyledFormContainer>
  );
}

export default RankForm;

const StyledFormContainer = styled.div`
  border-radius: 20px;
  background-color: #453b3b99;
  white-space: nowrap;
  color: white;

  .name-text {
    color: white;
  }
  .congratulation-text {
    font-size: 2em;
  }
  .rank-text {
    font-size: 1em;
  }

  padding: 30px 20px;

  @media only screen and (min-width: 1200px) {
    padding: 70px 100px;
  }
`;
