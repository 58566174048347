import { Direction } from "../type/gameType";
import { areEqual } from "./math";

export function randomEnum<T>(anEnum: T): T[keyof T] {
    const enumValues = Object.keys(anEnum)
        .map(n => Number.parseInt(n))
        .filter(n => !Number.isNaN(n)) as unknown as T[keyof T][]
    const randomIndex = Math.floor(Math.random() * enumValues.length)
    const randomEnumValue = enumValues[randomIndex]
    return randomEnumValue;
}

export function checkValidDirection(oldDir: Direction, newDir: Direction): boolean {
    return (!areEqual([Direction.LEFT, Direction.RIGHT], [oldDir, newDir])
        && !areEqual([Direction.UP, Direction.DOWN], [oldDir, newDir]))
}

