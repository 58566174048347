import React from 'react';
import { Divider, FormControl, InputLabel, MenuItem, Select, Slider, Stack } from '@mui/material';
import { difficultyLevel } from '../../../../config/gameConfig';
import { capitalizeFirstLetter } from '../../../../utils/letter';
import { useGameConfig } from '../../../context/GameConfigContext';
import { useStyleTheme } from '../../../provides/StyleThemeProvider/StyleThemeProvider';
import ThemeSwitch from '../../ThemeSwitch';
import styled from 'styled-components';
import InputText from './InputText';
import ResetConfigButton from '../../GameButton/ResetConfigButton';

function valuetext(value: number) {
    return `${value}`;
}

function SwipeablePanel() {
    const { switchTheme } = useStyleTheme()

    const {
        handleResetClick,
        handleChangeSquareWidth,
        handleChangeRowColumn,
        handleChangeDifficulty,
        handleChangeFramerate,
        squareWidth,
        row,
        column,
        difficulty,
        framerate, } = useGameConfig()


    return <StyledConfigSwipeDrawerContainer>
        {/* <div >Theme</div> */}
        {/* <ThemeSwitch switchCanvasTheme={switchTheme} /> */}
        <Divider />
        <FormControl fullWidth style={{ marginTop: 24, marginBottom: 12 }} >
            <InputLabel >Difficulty</InputLabel>
            <Select
                value={difficulty}
                label="difficult"
                onChange={(e: any) => { handleChangeDifficulty(e.target.value) }}
            >
                {Object.keys(difficultyLevel).map(level => {
                    return < MenuItem key={level} value={level} > {capitalizeFirstLetter(level)}</MenuItem>
                })}
            </Select>
        </FormControl>
        <div>size: {squareWidth}</div>
        <Slider
            value={squareWidth}
            aria-label="size"
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            onChange={(e: any) => { handleChangeSquareWidth(e.target.value) }}
            step={1}
            marks
            min={5}
            max={30}
        />
        <div>speed: {framerate}</div>
        <Slider
            value={framerate}
            aria-label="framework"
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            onChange={(e: any) => { handleChangeFramerate(e.target.value) }}
            step={1}
            marks
            min={1}
            max={60}
        />
        <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={3}>
                <InputText
                    value={row || ""}
                    type="number"
                    label="row"
                    handleTextOnChange={(e: any) => {
                        const changedRow = Number(e.target.value)
                        if (isNaN(Number(changedRow)) || Number(changedRow) > 500) {
                            return "error"
                        }
                        handleChangeRowColumn(changedRow, column)
                    }}
                />
                <InputText
                    value={column || ""}
                    type="number"
                    label="column"
                    handleTextOnChange={(e: any) => {
                        const changedColumn = Number(e.target.value)
                        if (isNaN(Number(changedColumn)) || Number(changedColumn) > 500) {
                            return "error"
                        }
                        handleChangeRowColumn(row, changedColumn)
                    }}
                />
            </Stack>
            <ResetConfigButton onClick={handleResetClick} />
        </Stack>
    </StyledConfigSwipeDrawerContainer >
}

export default SwipeablePanel;

const StyledConfigSwipeDrawerContainer = styled.div`
    padding:24px 24px;
`