import React, { ReactChild } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Canvas } from "../../../helper/CanvasController";
import useDirection from "../../../hook/useDirection";
import useKeyPress from "../../../hook/useKeyPress";
import {
  makeSelectorCanvasHeight,
  makeSelectorCanvasHeightOffset,
  makeSelectorCanvasWidth,
  makeSelectorCanvasWidthOffset,
  makeSelectorColumn,
  makeSelectorDifficulty,
  makeSelectorGameMode,
  makeSelectorRow,
  makeSelectorSquareWidth,
} from "../../../redux/configReducer/selectors";
import { useSnakeGameSlice } from "../../../redux/gameReducer/reducer";
import {
  makeSelectorClearPosition,
  makeSelectorFoodPosition,
  makeSelectorFramerate,
  makeSelectorGameDuration,
  makeSelectorGameIsEnd,
  makeSelectorGameIsPause,
  makeSelectorGameIsStart,
  makeSelectorGamePlayerLevel,
  makeSelectorGamePlayerFood,
  makeSelectorSnakeDirection,
  makeSelectorSnakePosition,
  makeSelectorToxicFoodPosition,
  makeSelectorGamePlayerScore,
  makeSelectorWallPosition,
} from "../../../redux/gameReducer/selectors";
import { Coordinate, Direction, GameResult } from "../../../type/gameType";
import { checkValidDirection } from "../../../utils/game-direction";
import {
  calculateClassModeGameScorePerFood,
  calculateFinalGameSpeed,
  calculateLevelUnderCertainNumberOfFood,
  calculateNextSnakePosition,
  checkEndGame,
  checkFoodIsEaten,
  createNewFoodPosition,
} from "../../../utils/game-logic";
import { CanvasContext } from "../../context/CanvasContext";
import { SnakeGameContext } from "../../context/SnakeGameContext";
import { useStyleTheme } from "../../provides/StyleThemeProvider/StyleThemeProvider";
import styled from "styled-components";
import _ from "lodash";
import { snakeGameRankingService } from "../../../Services/SnakeGameRankingService";
import { Modal } from "@mui/material";
import RankForm from "../../components/OnRankListForm/RankForm";
import EndGameButton from "../../components/GameButton/EndGameButton";
import PausePlayButton from "../../components/GameButton/PausePlayButton";
import { snakeLevelColor } from "../../../config/gameStyleConfig";
import { colorLuminance, randomColorHexcode } from "../../../utils/color";

interface GamePlatformProps {
  children?: ReactChild;
}

function GamePlatform({ children }: GamePlatformProps) {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const handleRankOnListFormModalOpen = () => setModalIsOpen(true);
  const handleRankOnListFormModalClose = () => setModalIsOpen(false);
  const [rank, setRank] = React.useState<number>(0);
  const [rankList, setRankList] = React.useState<any>([]);
  const [gameResult, setGameResult] = React.useState<any>({});

  const { themeStyle, isTransparentMode } = useStyleTheme();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [ctx, setCtx] = React.useState<CanvasRenderingContext2D | null>(null);
  const [gameIsJustInit, setGameIsJustInit] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const { actions: snakeGameActions } = useSnakeGameSlice();

  const { handlers, snakeDirection: snakeNewDirection, resetDirection } = useDirection();
  const { keyPressed: isSpacePress } = useKeyPress(" ", true);

  // game mode
  const gameMode = useSelector(makeSelectorGameMode());

  // config
  const difficulty = useSelector(makeSelectorDifficulty());
  const squareWidth = useSelector(makeSelectorSquareWidth());
  const row = useSelector(makeSelectorRow());
  const column = useSelector(makeSelectorColumn());
  const canvasWidth = useSelector(makeSelectorCanvasWidth());
  const canvasHeight = useSelector(makeSelectorCanvasHeight());
  const canvasWidthOffset = useSelector(makeSelectorCanvasWidthOffset());
  const canvasHeightOffset = useSelector(makeSelectorCanvasHeightOffset());

  // game information
  const framerate = useSelector(makeSelectorFramerate());
  const foodPosition = useSelector(makeSelectorFoodPosition());
  const snakePosition = useSelector(makeSelectorSnakePosition());
  const toxicFoodPosition = useSelector(makeSelectorToxicFoodPosition());
  const clearPosition = useSelector(makeSelectorClearPosition());
  const wallPosition = useSelector(makeSelectorWallPosition());
  const snakeDirection = useSelector(makeSelectorSnakeDirection());
  const gameIsEnd = useSelector(makeSelectorGameIsEnd());
  const gameIsPause = useSelector(makeSelectorGameIsPause());
  const gameIsStart = useSelector(makeSelectorGameIsStart());
  const gameDuration = useSelector(makeSelectorGameDuration());

  // game player information
  const gamePlayerLevel = useSelector(makeSelectorGamePlayerLevel());
  const gamePlayerFood = useSelector(makeSelectorGamePlayerFood());
  const gamePlayerScore = useSelector(makeSelectorGamePlayerScore());

  // init function
  /** debounce is used in order not to run this function close faster than gameIsJustInit state changes */
  const gameInit = _.debounce(
    React.useCallback(() => {
      switch (gameMode) {
        case "custom": {
          dispatch(snakeGameActions.gameClassicInit({ row, column, difficulty }));
          break
        }
        case "classic": {
          dispatch(snakeGameActions.gameClassicInit({ row, column, difficulty }));
          break
        }
        case "survival": {
          dispatch(snakeGameActions.gameSurvivalInit({ row, column, difficulty }));
          break
        }
      }
      resetDirection()

      setGameIsJustInit(true);
    }, [row, column, difficulty]),
    0
  );

  const gameStart = () => {
    dispatch(snakeGameActions.startGame());
  };

  const gamePlayPause = () => {
    dispatch(snakeGameActions.playPauseGame());
  };

  const changeGameIsEndState = (isEndGame: boolean) => {
    dispatch(snakeGameActions.endGame({ gameIsEnd: isEndGame }));
  };

  const runGameCycle = React.useCallback(() => {
    if (snakeNewDirection === null) return
    switch (gameMode) {
      case "custom": {
        dispatch(snakeGameActions.gameClassicCycle({ snakeNewDirection, row, column }))
        break
      }
      case "classic": {
        dispatch(snakeGameActions.gameClassicCycle({ snakeNewDirection, row, column }))
        break
      }
      case "survival": {
        dispatch(snakeGameActions.gameSurvivalCycle({ snakeNewDirection, row, column, difficulty }))
        break
      }
    }
  }, [snakeNewDirection, row, column]);

  const drawGrid = React.useCallback(() => {
    Canvas.clearCanvasGrid(ctx, canvasRef.current);
    Canvas.drawGrid(
      ctx,
      row,
      column,
      squareWidth,
      themeStyle.background.fillColor(isTransparentMode),
      themeStyle.background.strokeColor,
      canvasWidthOffset,
      canvasHeightOffset
    );
  }, [
    ctx,
    row,
    column,
    squareWidth,
    themeStyle,
    canvasWidthOffset,
    canvasHeightOffset,
  ]);

  const drawFood = React.useCallback(() => {
    Canvas.drawRectList(
      foodPosition,
      ctx,
      row,
      column,
      squareWidth,
      themeStyle.food.fillColor,
      themeStyle.food.strokeColor,
      canvasWidthOffset,
      canvasHeightOffset
    );
  }, [
    ctx,
    foodPosition,
    row,
    column,
    squareWidth,
    themeStyle,
    canvasWidthOffset,
    canvasHeightOffset,
  ]);

  const drawSnake = React.useCallback(() => {
    Canvas.drawRectList(
      snakePosition,
      ctx,
      row,
      column,
      squareWidth,
      gamePlayerLevel < snakeLevelColor.length ? themeStyle.snake.fillColor[gamePlayerLevel] : randomColorHexcode(),
      themeStyle.snake.strokeColor,
      canvasWidthOffset,
      canvasHeightOffset
    );

    Canvas.drawRectList(
      [snakePosition[0]],
      ctx,
      row,
      column,
      squareWidth,
      gamePlayerLevel < snakeLevelColor.length ? colorLuminance(themeStyle.snake.fillColor[gamePlayerLevel], -0.4) : colorLuminance(randomColorHexcode(), -0.4),
      themeStyle.snake.strokeColor,
      canvasWidthOffset,
      canvasHeightOffset
    );

  }, [
    ctx,
    snakePosition,
    row,
    column,
    squareWidth,
    themeStyle,
    canvasWidthOffset,
    gamePlayerLevel,
    canvasHeightOffset,
  ]);

  const drawWall = React.useCallback(() => {
    Canvas.drawRectList(
      wallPosition,
      ctx,
      row,
      column,
      squareWidth,
      themeStyle.wall.fillColor,
      themeStyle.wall.strokeColor,
      canvasWidthOffset,
      canvasHeightOffset
    );
  }, [
    ctx,
    wallPosition,
    row,
    column,
    squareWidth,
    themeStyle,
    canvasWidthOffset,
    canvasHeightOffset,
  ]);

  const drawToxicFood = React.useCallback(() => {
    Canvas.drawRectList(
      toxicFoodPosition,
      ctx,
      row,
      column,
      squareWidth,
      themeStyle.toxicFood.fillColor,
      themeStyle.toxicFood.strokeColor,
      canvasWidthOffset,
      canvasHeightOffset
    );
  }, [
    ctx,
    toxicFoodPosition,
    row,
    column,
    squareWidth,
    themeStyle,
    canvasWidthOffset,
    canvasHeightOffset,
  ]);

  const cleanUnusedRect = React.useCallback(() => {
    Canvas.clearRectList(
      clearPosition,
      ctx,
      row,
      column,
      squareWidth,
      canvasWidthOffset,
      canvasHeightOffset
    );
    Canvas.drawRectList(
      clearPosition,
      ctx,
      row,
      column,
      squareWidth,
      themeStyle.background.fillColor(isTransparentMode),
      themeStyle.background.strokeColor,
      canvasWidthOffset,
      canvasHeightOffset
    );
  }, [
    ctx,
    clearPosition,
    row,
    column,
    squareWidth,
    themeStyle,
    canvasWidthOffset,
    canvasHeightOffset,
  ]);

  // draw initial canvas
  const drawAll = React.useCallback(() => {
    drawGrid();
    drawFood();
    drawSnake();
    drawToxicFood();
    drawWall();
  }, [drawGrid, drawFood, drawSnake, drawToxicFood, drawWall]);

  const draw = React.useCallback(() => {
    drawFood();
    drawSnake();
    drawToxicFood();
    cleanUnusedRect();
    drawWall()
  }, [drawFood, drawSnake, cleanUnusedRect, drawWall]);

  // press space bar start game
  React.useEffect(() => {
    if (gameIsEnd || !gameIsStart) {
      if (isSpacePress && !modalIsOpen) {
        gameInit()
      }
    }
  }, [isSpacePress]);

  React.useEffect(() => {
    if (snakeNewDirection === null) return
    if (gameIsEnd || !gameIsStart) {
      if (!modalIsOpen) {
        gameStart()
      }
    }
  }, [snakeNewDirection])

  // snake position change and draw
  React.useEffect(() => {
    if (!gameIsStart || gameIsPause) return;
    draw();
  }, [snakePosition]);

  // run frame
  React.useEffect(() => {
    if (!gameIsStart || gameIsEnd || gameIsPause) return;
    runGameCycle()

    setTimeout(() => {
      dispatch(
        snakeGameActions.updateGameDuration({
          gameDuration:
            gameDuration +
            1000 / calculateFinalGameSpeed(gamePlayerLevel, framerate),
        })
      );
    }, 1000 / calculateFinalGameSpeed(gamePlayerLevel, framerate));
  }, [gameIsStart, gameIsEnd, gameIsPause, gameDuration]);

  // create canvas
  React.useEffect(() => {
    if (!ctx && canvasRef.current) {
      setCtx(canvasRef.current.getContext("2d"));
    }
  }, [ctx]);

  // first init and  change setting
  React.useEffect(() => {
    if (!ctx) return;
    gameInit();
    Canvas.clearCanvasGrid(ctx, canvasRef.current);
  }, [ctx, squareWidth, row, column, difficulty]);

  // change theme
  React.useEffect(() => {
    drawAll();
  }, [themeStyle]);

  // draw after game is init
  React.useEffect(() => {
    if (gameIsJustInit) {
      drawAll();
      setGameIsJustInit(false);
    }
  }, [gameIsJustInit]);

  // game end and on list check
  React.useEffect(() => {
    async function endGameRankingCheck() {
      const gameResult: GameResult = {
        duration: gameDuration,
        food: gamePlayerFood,
        level: gamePlayerLevel,
        score: gamePlayerScore,
      };
      const response = await snakeGameRankingService.checkIsOnRankList(
        gameMode,
        difficulty,
        gameResult
      );
      if (response.rank) {
        const { rank, rankList } = response;
        setRank(rank);
        setRankList(rankList);
        setGameResult(gameResult);
        handleRankOnListFormModalOpen();
      }
    }
    if (gameIsEnd && gameMode !== "custom") {
      endGameRankingCheck();
    }
  }, [gameIsEnd]);

  return (
    <CanvasContext.Provider value={ctx}>
      <SnakeGameContext.Provider
        value={{
          gameInit,
          gameStart,
          gamePlayPause,
          runGameCycle,
          drawAll,
          draw,
          changeGameIsEndState,
          snakeControlDirection: snakeNewDirection,
        }}
      >
        <StyledCanvasContainer>
          <StyledGamingButtonContainer>
            {<EndGameButton className="button" />}
            {<PausePlayButton className="button" />}
          </StyledGamingButtonContainer>
          <canvas
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
          ></canvas>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: gameIsStart ? 0 : 2,
            }}
            {...handlers}
          />
          {children}
          <Modal
            open={modalIsOpen}
            onClose={handleRankOnListFormModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StyleModalRankFormContainer>
              <RankForm
                gameMode={gameMode}
                difficulty={difficulty}
                rank={rank}
                gameResult={gameResult}
                rankList={rankList}
                handleRankOnListFormModalClose={handleRankOnListFormModalClose}
              />
            </StyleModalRankFormContainer>
          </Modal>
        </StyledCanvasContainer>
      </SnakeGameContext.Provider>
    </CanvasContext.Provider>
  );
}

export default GamePlatform;

const StyledGamingButtonContainer = styled.div`
    z-index:2;
    width:100%;
    display:flex;
    justify-content:center;

    .button-margin{
        margin:0px 12px;
    }
`
const StyledCanvasContainer = styled.div`
  padding: 0px;
  margin: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyleModalRankFormContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
