import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectorGamePlayerScore } from '../../../redux/gameReducer/selectors';

interface ScoreComponentProps {
    className: string
}

function ScoreComponent({ className }: ScoreComponentProps) {
    const score = useSelector(makeSelectorGamePlayerScore())

    return <div className={className}>Score: {score}</div>;
}

export default ScoreComponent;
