import * as React from 'react'
import { Level } from '../../type/gameType'

export const GAME_CONFIG_DEFAULT_CONTEXT_VALUES: GameConfigContextValues = {
    handleResetClick: () => { },
    handleChangeSquareWidth: () => { },
    handleChangeRowColumn: () => { },
    handleChangeDifficulty: () => { },
    handleChangeFramerate: () => { },
    squareWidth: 30,
    row: 0,
    column: 0,
    difficulty: "medium",
    framerate: 15
}

export interface GameConfigContextValues {
    handleResetClick: () => void
    handleChangeSquareWidth: (squareWidth: number) => void
    handleChangeRowColumn: (row: number, column: number) => void
    handleChangeDifficulty: (difficulty: Level) => void
    handleChangeFramerate: (framerate: number) => void
    squareWidth: number
    row: number
    column: number
    difficulty: Level
    framerate: number
}

export const GameConfigContext = React.createContext<GameConfigContextValues>(
    GAME_CONFIG_DEFAULT_CONTEXT_VALUES,
)

export const useGameConfig = () => React.useContext(GameConfigContext)
