import React from 'react';
import DirectionAnimationComponent from '../DirectionAnimationComponent';

interface GameStartButtonProps {
    className?: string
}

function GameStartButton({ className }: GameStartButtonProps) {

    return <div className={className}>
        <DirectionAnimationComponent />
    </div>;
}

export default GameStartButton;
