import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectorGamePlayerFood } from '../../../redux/gameReducer/selectors';

interface ScoreComponentProps {
    className?: string
}

function FoodComponent({ className }: ScoreComponentProps) {

    const food = useSelector(makeSelectorGamePlayerFood())

    return <div className={className}>Food: {food}</div>;
}

export default FoodComponent;
