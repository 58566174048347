import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { globalTheme } from '../../../config/generalStyle';
import { makeSelectorGameMode } from '../../../redux/configReducer/selectors';
import DurationComponent from '../../components/GameCounter/DurationComponent';
import FoodComponent from '../../components/GameCounter/FoodComponent';
import LevelComponent from '../../components/GameCounter/LevelComponent';
import ScoreComponent from '../../components/GameCounter/ScoreComponent';

const CustomGameComponent = () => {
    return <StyledConfigContainer>
        <DurationComponent className="status-text status-margin" />
        <LevelComponent className="status-text  status-margin" />
        <FoodComponent className="status-text  status-margin" />
        <ScoreComponent className="status-text  status-margin" />
    </StyledConfigContainer>
}

const ClassicComponent = () => {
    return <StyledConfigContainer>
        <ScoreComponent className="status-text  status-margin" />
    </StyledConfigContainer>
}

const SurvivalComponent = () => {
    return <StyledConfigContainer>
        <DurationComponent className="status-text  status-margin" />
    </StyledConfigContainer>
}

function PlayerStatusPlatform() {
    const gameMode = useSelector(makeSelectorGameMode())

    switch (gameMode) {
        case "custom": {
            return <CustomGameComponent />
        }
        case "classic": {
            return <ClassicComponent />
        }
        case "survival": {
            return <SurvivalComponent />
        }
        default: {
            return <CustomGameComponent />
        }
    }

}

export default PlayerStatusPlatform;

const StyledConfigContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    white-space:nowrap;
    
    .status-text{
        color:${globalTheme.text.color};
        font-size:1em;
    }

    @media only screen and (min-width: 728px) {
        .status-text{
            font-size:3em;
        }
    }
    
    .status-margin{
        margin:0px 12px;
    }
`