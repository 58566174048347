import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GameMode, Level } from "../../type/gameType";
import { useInjectReducer } from "../../utils/redux-injectors";
import { GameConfigState } from "./types";

const defaultSquareWidth = 15
const fullWidth = window.innerWidth
const fullHeight = 0.7 * window.innerHeight
const defaultWidth = fullWidth
const defaultHeight = fullHeight

export const initialState = {
    squareWidth: defaultSquareWidth,
    canvasWidth: defaultWidth,
    canvasHeight: defaultHeight,
    row: (Math.floor(defaultWidth / defaultSquareWidth)),
    column: (Math.floor(defaultHeight / defaultSquareWidth)),
    // canvasWidthOffset: Math.ceil((defaultWidth - (Math.floor(defaultWidth / defaultSquareWidth)) * defaultSquareWidth) / 2),
    // canvasHeightOffset: Math.ceil((defaultHeight - (Math.floor(defaultHeight / defaultSquareWidth)) * defaultSquareWidth) / 2),
    canvasWidthOffset: 0,
    canvasHeightOffset: 0,
    difficulty: "difficult" as Level,
    gameMode: "custom" as GameMode
}

const slice = createSlice({
    name: "gameConfig",
    initialState,
    reducers: {
        updateState(state, action: PayloadAction<Partial<GameConfigState>>) {
            return {
                ...state,
                ...action.payload,
            }
        },
        updateRowColumn(state, action: PayloadAction<{ row: number, column: number }>) {
            const { row, column } = action.payload
            if (row / column > fullWidth / fullHeight) {
                state.squareWidth = Math.floor(fullWidth / row)
            } else {
                state.squareWidth = Math.floor(fullHeight / column)
            }
            state.row = row
            state.column = column
            state.canvasWidth = state.squareWidth * row
            state.canvasHeight = state.squareWidth * column
        },
        updateSquareWidth(state, action: PayloadAction<{ squareWidth: number }>) {
            const { squareWidth } = action.payload
            state.squareWidth = squareWidth
            state.row = Math.floor(fullWidth / squareWidth)
            state.column = Math.floor(fullHeight / squareWidth)
            state.canvasWidth = squareWidth * state.row
            state.canvasHeight = squareWidth * state.column
        },
        updateDifficulty(state, action: PayloadAction<{ difficulty: Level }>) {
            state.difficulty = action.payload.difficulty
        },
        resetConfig(state, action: PayloadAction) {
            return initialState
        },
        switchGameMode(state, action: PayloadAction<{ gameMode: GameMode }>) {
            state.gameMode = action.payload.gameMode
        }
    }
})

export const { actions: gameConfigAction } = slice

export const useGameConfigSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer })
    return { actions: slice.actions }
}