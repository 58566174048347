import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import StyleThemeProvider from './provides/StyleThemeProvider/StyleThemeProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import HomePage from './Router/HomePage';
import CustomGame from './Router/CustomGame';
import ClassicGame from './Router/ClassicGame';
import { useGameConfigSlice } from '../redux/configReducer/reducer';
import AppLayout from './components/AppLayout.tsx/AppLayout';
import LeaderBoard from './Router/LeaderBoard';
import SurvivalGame from './Router/SurvivalGame';

function App() {
  useGameConfigSlice()


  return (
    <StyleThemeProvider>
      <ConfirmProvider>

        <Router>
          <AppLayout>
            <Switch>
              <Route exact path="/custom" component={CustomGame} />
              <Route exact path="/classic" component={ClassicGame} />
              <Route exact path="/survival" component={SurvivalGame} />
              <Route exact path="/leaderboard" component={LeaderBoard} />
              <Route exact path="/" component={HomePage} />
            </Switch>
          </AppLayout>
        </Router>

      </ConfirmProvider>
    </StyleThemeProvider>
  );
}

export default App;
