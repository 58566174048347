import {
  GameMode,
  GamePlayerResult,
  GameResult,
  Level,
} from "../type/gameType";
import { firebaseDatabase, FirebaseDatabase } from "./firebase/database";

export class SnakeGameRankingService {
  constructor(private firebaseService: FirebaseDatabase) { }

  getRanking = async (gameMode: Partial<GameMode>, difficulty: Partial<Level>) => {
    return await this.firebaseService.get(`/ranking/${gameMode}/${difficulty}`);
  };

  updateRanking = async (
    gameMode: GameMode,
    difficulty: Partial<Level>,
    gameRanking: GamePlayerResult[]
  ) => {
    return await this.firebaseService.update(`/ranking/${gameMode}`, {
      [difficulty]: JSON.stringify(gameRanking),
    });
  };

  getRankUnderVariousMode = (
    gameMode: GameMode,
    originalRankList: GamePlayerResult[],
    gameResult: GameResult
  ): number => {
    switch (gameMode) {
      case "classic": {
        for (let i = 0; i < originalRankList.length; i++) {
          if (originalRankList[i].gameResult.score < gameResult.score) {
            /** index from 0 and ranking from 1 and if the player defeat index
             * no. 3 which mean defeat rank 4 player then this player become rank 3*/
            return i + 1;
          }
        }
        break;
      }
      case "survival": {
        for (let i = 0; i < originalRankList.length; i++) {
          if (originalRankList[i].gameResult.duration < gameResult.duration) {
            /** index from 0 and ranking from 1 and if the player defeat index
             * no. 3 which mean defeat rank 4 player then this player become rank 3*/
            return i + 1;
          }
        }
        break;
      }
    }
    return originalRankList.length + 1;
  };

  checkIsOnRankList = async (
    gameMode: Partial<GameMode>,
    difficulty: Partial<Level>,
    gameResult: GameResult
  ) => {
    try {
      const data = await this.getRanking(gameMode, difficulty);

      if (!data) {
        return {
          isOnList: true,
          rankList: null,
          rank: 1,
        };
      }

      if (typeof data === "string") {
        const originalRankList = JSON.parse(data);
        const rank = this.getRankUnderVariousMode(
          gameMode,
          originalRankList,
          gameResult
        );

        if (rank <= 8) {
          return {
            isOnList: true,
            rankList: originalRankList,
            rank: rank,
          };
        }
      }

      return {
        isOnList: false,
      };
    } catch (err) {
      return {
        isError: true,
        error: err,
      };
    }
  };

  updateNewRankList = async (
    gameMode: Partial<GameMode>,
    rank: number,
    previousRankList: GamePlayerResult[] | null,
    name: string,
    gameResult: GameResult,
    difficulty: Partial<Level>
  ) => {
    try {
      let latestRankList;

      if (!Array.isArray(previousRankList) || !previousRankList) {
        latestRankList = [{ name, gameResult }]

      } else {

        /** rank is from 1 and index is from 0 so need -1 */
        const newRankList = [
          ...(previousRankList as any as GamePlayerResult[]),
        ];

        const tailRankList = newRankList.splice(rank - 1);
        newRankList.push({ name, gameResult });
        latestRankList = [...newRankList, ...tailRankList];
        if (latestRankList.length > 8) latestRankList.length = 8;
      }

      await this.updateRanking(gameMode, difficulty, latestRankList);
      return { updateIsFinish: true };

    } catch (err) {
      return {
        isError: true,
        error: err,
      };
    }
  };
}

export const snakeGameRankingService = new SnakeGameRankingService(
  firebaseDatabase
);
