import { Stack } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { gameModeQuality, gameModeRankingQualityConvertor } from '../../../config/gameConfig';
import { snakeGameRankingService } from '../../../Services/SnakeGameRankingService';
import { GameMode, GamePlayerResult, Level } from '../../../type/gameType';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const sortingFunction = {
    classic: (first: GamePlayerResult, second: GamePlayerResult) => first.gameResult.score >= second.gameResult.score ? -1 : 1,
    survival: (first: GamePlayerResult, second: GamePlayerResult) => first.gameResult.duration >= second.gameResult.duration ? -1 : 1,
}

interface LeaderboardTableProps {
    gameMode: GameMode,
    difficulty: Level
}

function LeaderboardTable({ gameMode, difficulty }: LeaderboardTableProps) {
    const [rankData, setRankData] = React.useState<GamePlayerResult[] | null>(null)

    React.useEffect(() => {
        async function fetchData() {
            const rankList = (JSON.parse(await snakeGameRankingService.getRanking(gameMode, difficulty) as string) as GamePlayerResult[])
            if (rankList) {
                rankList.sort((sortingFunction as any)[gameMode])
                if (rankList.length < 8) {
                    setRankData(rankList.concat(Array(8 - rankList.length).fill(null)))
                } else {
                    setRankData(rankList)
                }
            } else {
                setRankData(Array(8).fill(null))
            }
        }
        fetchData()
    }, [gameMode, difficulty])

    return <StyledRankingListContainer>
        {/* <div className="list-container rank-title">
            <div className="rank-text">Rank</div>
            <div className="name-text">Name</div>
            <div className="score-text">{(gameModeQuality as any)[gameMode]}</div>
        </div> */}
        {rankData && rankData?.map((item, index) => {
            return item ? <div key={index} className="list-container">
                <div className="rank-text">{index === 0 && <EmojiEventsIcon />}{index + 1}</div>
                <div className="name-text">{item.name}</div>
                <div className="score-text">{gameModeRankingQualityConvertor(gameMode, item.gameResult)}</div>
            </div> :
                <div key={index} className="list-container">
                    <div className="rank-text">{index === 0 && <EmojiEventsIcon />}{index + 1}</div>
                    <div className="name-text">----</div>
                    <div className="score-text">----</div>
                </div>
        })}
    </StyledRankingListContainer >
}

export default LeaderboardTable;

const StyledRankingListContainer = styled.div`
    padding-bottom:70px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;


    .list-container{
        background: rgb(255,255,255);
        background: linear-gradient(138deg, rgba(255,255,255,1) 0%, rgba(125,123,123,1) 50%, rgba(0,0,0,1) 100%);
        display:flex;
        align-items:center;
        width:100%;
        margin:20px 0px;
        padding:30px 10px;
        border-radius:10px;
        font-size:1em;

        .rank-text{
            flex:1;
            display:flex;
            align-items:center;
        }
        .name-text{
            flex:5;
        }
        .score-text{
            flex:2;
            color:white;
        }
    }
`