import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useRouteContext } from '../provides/RouteProvider/RouteProvider';
import { globalTheme } from '../../config/generalStyle';

const comingSoonSource = "./game-image/coming-soon.png"

const gameModes = [
    {
        name: "custom",
        title: "CUSTOM",
        path: "/custom",
        src: "./game-image/custom.png",
        description: "Practice mode. Player can create custom environment to practice. (speed, size, difficulty...)",
        ready: true
    },
    {
        name: "classic",
        title: "CLASSIC",
        path: "/classic",
        src: "./game-image/classic.png",
        description: "Player target to eat food as soon as possible to get more score. Mode provide a ranking system",
        ready: true
    },
    {
        name: "survival",
        title: "SURVIVAL",
        path: "/survival",
        src: "./game-image/survival.png",
        description: "Player target to live longer while the area will be narrowed down in every 10s. Mode provide a ranking system",
        ready: true
    },
    {
        name: "versus",
        title: "VERSUS",
        path: "/versus",
        src: "./game-image/versus.png",
        description: "",
        ready: false
    }
]

function HomePage() {
    const { changePath } = useRouteContext()
    const history = useHistory()

    return <StyledHomeContainer>
        <StyledHeader>
            Welcome to Snake Game
        </StyledHeader>

        <StyledMainContent>
            {gameModes.map(gameMode => {
                return <div key={gameMode.name} className="image" onClick={() => {
                    if (gameMode.ready) {
                        changePath(gameMode.path)
                        history.push(gameMode.path)
                    }
                }}>
                    <Avatar className={`game-image ${!gameMode.ready && "not-ready"}`} src={gameMode.src} variant="rounded" alt="image" />
                    {gameMode.ready && <div className='mode-description-container'>
                        <div className={`title`}>
                            {gameMode.title}
                        </div>
                        <div className='description'>
                            {gameMode.description}
                        </div>
                    </div>}
                    {!gameMode.ready && <img className="coming-soon-image" src={comingSoonSource} alt="image" />}
                </div>
            })}
        </StyledMainContent>

        <StyledFooter></StyledFooter>
    </StyledHomeContainer>;
}

export default HomePage;

const StyledHomeContainer = styled.div`
    width:100%;
    height:100%;
    overflow-y:scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
    }
`

const StyledHeader = styled.header`
    display:flex;
    justify-content:space-around;
    align-items:center;
    font-size:6vw;
    white-space:nowrap;
    color:${globalTheme.text.color};
`

const StyledMainContent = styled.div`
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-wrap:wrap;
    margin:24px 0px;

    .image{
        position:relative;
        width:70vw;
        height:70vw;
        margin:12px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;    
        cursor:pointer;
        
        @media only screen and (min-width: 1200px) {
            width:30vw;
            height:30vw;
        }

        &:hover{
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            bottom:2px;
            left:2px;
        }
        
        .game-image{
            position:absolute;
            left:0px;
            right:0px;
            width:100%;
            height:100%;
            
        }
    
        .not-ready{
            filter: blur(10px);
        }

        .coming-soon-image{
            position:absolute;
            width:100%;
            height:100%;
            opacity:0.5;
        }

        .mode-description-container{
            position:absolute;
            height:20%;
            bottom:0px;
            padding:20px;
            color:${globalTheme.text.color};
            background-color:${globalTheme.gameTitleBackground.color};
            opacity:0.9;
            
            .title{
                font-size:1em;
                font-weight:bold;   
            }

            .description{
                font-size:0.2em;
                font-weight:bold;   
            }

            @media only screen and (min-width: 728px) {
                .title{
                    font-size:1.5em;
                }

                .description{
                    font-size:1em;  
                }
            }
        }
    }
`

const StyledFooter = styled.footer`
    padding:30px;
`
