import React from 'react';
import { useSelector } from 'react-redux';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { Button } from '@mui/material';
import { makeSelectorGameIsPause } from '../../../redux/gameReducer/selectors';
import useKeyPress from '../../../hook/useKeyPress';
import { useSnakeGame } from '../../context/SnakeGameContext';

interface PausePlayButtonProps {
    className?: string
}

function PausePlayButton({ className }: PausePlayButtonProps) {
    const { gamePlayPause } = useSnakeGame()
    const { keyPressed: isPKeyPress } = useKeyPress("p", true)

    const gameIsPause = useSelector(makeSelectorGameIsPause())

    React.useEffect(() => {
        if (isPKeyPress) {
            gamePlayPause()
        }
    }, [isPKeyPress])

    return <div className={className}>
        <Button
            variant="outlined"
            color={gameIsPause ? "primary" : "warning"}
            onClick={gamePlayPause}>
            {gameIsPause ? <PlayArrowIcon /> : <StopIcon />}
        </Button >
    </div>;
}

export default PausePlayButton;
