import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '../../config/generalStyle';
import { GameMode, Level } from '../../type/gameType';
import LeaderboardTable from '../components/Leaderboard/LeaderboardTable';

const gameModeList: GameMode[] = ["classic", "survival"]
const difficultyList: Level[] = ["easy", "difficult", "extremely"]


function LeaderBoard() {
    const [showGameMode, setShowGameMode] = React.useState<GameMode>("classic")
    const [showDifficulty, setShowDifficulty] = React.useState<Level>("difficult")

    const handleGameModeSelect = (gameMode: GameMode) => {
        setShowGameMode(gameMode)
    }

    const handleDifficultySelect = (difficulty: Level) => {
        setShowDifficulty(difficulty)
    }

    return <LeaderboardContainer>
        <div className='title'>Leaderboard</div>

        <div className='game-mode-button-container'>
            {
                gameModeList.map((item, index) => {
                    return <Button key={index} variant="text" className={`btn game-mode ${showGameMode === item && "selected"}`} onClick={
                        () => handleGameModeSelect(item)
                    }> {item}</Button>
                })
            }
            <div className='difficulty-button-container'>
                {
                    difficultyList.map((item, index) => {
                        return <Button key={index} variant="text" className={`btn difficulty ${showDifficulty === item && "selected"}`} onClick={
                            () => handleDifficultySelect(item)
                        } > {item}</Button>
                    })
                }
            </div>
        </div>


        <LeaderboardTable gameMode={showGameMode} difficulty={showDifficulty} />
    </LeaderboardContainer >;
}

export default LeaderBoard;

const LeaderboardContainer = styled.div`
    padding:0px 30px;
    height:100%;
    overflow-y:scroll;
    overflow-x:hidden;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
    }

    .title{
        color:${globalTheme.text.color};
        font-size:2.5em;
    }

    .difficulty-button-container{
        position:relative;
        left:30px;
        margin:5px;
        left:30px;
    }
    .btn{
        color:${globalTheme.text.color};
    }
    .btn:hover{
        background-color:#C3C3C3;
        color:#191919;
    }
    .game-mode{
        font-size:1em;
    }
    .difficulty{
        font-size:0.5em;
    }
    .selected{
        background-color:#4a4747;
    }



`