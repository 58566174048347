import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Coordinate, Direction, Level } from "../../type/gameType";
import { calculateNumberOfToxicFood } from "../../Drepricate/utils/matrix";
import { checkValidDirection } from "../../utils/game-direction";
import { useInjectReducer } from "../../utils/redux-injectors";
import { SnakeGameState } from "./types";
import { createRandomCoordinate } from "../../utils/math";
import { calculateClassModeGameScorePerFood, calculateLevelUnderCertainNumberOfFood, calculateLevelUnderCertainTime, calculateNextSnakePosition, checkEndGame, checkFoodIsEaten, createNewFoodPosition, createWallPosition } from "../../utils/game-logic";
import { survivalToxicCreationWithDifficultyLevel } from "../../config/gameConfig";

const defaultFramerate = 15

export const initialState: SnakeGameState = {
    snakePosition: [{ x: 0, y: 0 }],
    toxicFoodPosition: [{ x: 0, y: 0 }],
    foodPosition: [{ x: 0, y: 0 }],
    clearPosition: [{ x: 0, y: 0 }],
    wallPosition: [{ x: 0, y: 0 }],
    snakeDirection: null,
    framerate: defaultFramerate,
    gameIsStart: false,
    gameIsPause: false,
    gameIsEnd: false,
    gameDuration: 0, // in milliseconds
    gamePlayerLevel: 1,
    gamePlayerFood: 0,
    gamePlayerScore: 0
}

const slice = createSlice({
    name: "snakeGame",
    initialState,
    reducers: {
        updateState(state, action: PayloadAction<Partial<SnakeGameState>>) {
            return {
                ...state,
                ...action.payload,
            }
        },
        startGame(state, action: PayloadAction) {
            state.gameIsStart = true
        },
        gameClassicInit(state, action: PayloadAction<{ row: number, column: number, difficulty: Level }>) {
            const { row, column, difficulty } = action.payload
            state.gameIsEnd = false
            state.gameIsPause = false
            state.gameIsStart = false
            state.snakeDirection = null
            state.wallPosition = []
            state.toxicFoodPosition = Array(calculateNumberOfToxicFood(row, column, difficulty)).fill(0).map(() => createRandomCoordinate(row, column, state.wallPosition))
            state.snakePosition = [createRandomCoordinate(row, column, [...state.wallPosition, ...state.toxicFoodPosition])]
            state.foodPosition = [createNewFoodPosition(state.snakePosition, [...state.wallPosition, ...state.toxicFoodPosition], row, column,)]
            state.gameDuration = 0
            state.gamePlayerLevel = 1
            state.gamePlayerFood = 0
            state.gamePlayerScore = 0
        },
        gameSurvivalInit(state, action: PayloadAction<{ row: number, column: number, difficulty: Level }>) {
            const { row, column, difficulty } = action.payload
            state.gameIsEnd = false
            state.gameIsPause = false
            state.gameIsStart = false
            state.snakeDirection = null
            state.wallPosition = createWallPosition(1, row, column)
            state.toxicFoodPosition = Array(calculateNumberOfToxicFood(row, column, difficulty)).fill(0).map(() => createRandomCoordinate(row, column, state.wallPosition))
            state.snakePosition = [createRandomCoordinate(row, column, [...state.wallPosition, ...state.toxicFoodPosition])]
            state.foodPosition = []
            state.gameDuration = 0
            state.gamePlayerLevel = 1
            state.gamePlayerFood = 0
            state.gamePlayerScore = 0
        },
        gameClassicCycle(state, action: PayloadAction<{ snakeNewDirection: Direction, row: number, column: number }>) {
            const { snakeNewDirection, row, column } = action.payload

            let nextDirection: Direction;
            let nextSnakePosition: Coordinate[];
            let newClearPosition: Coordinate[];
            let newFoodPosition: Coordinate[];
            let isEndGame: boolean;

            if (state.snakeDirection !== null) {
                nextDirection = checkValidDirection(state.snakeDirection, snakeNewDirection)
                    ? snakeNewDirection
                    : state.snakeDirection;
            } else {
                nextDirection = snakeNewDirection
            }
            nextSnakePosition = calculateNextSnakePosition(
                state.snakePosition,
                nextDirection,
                row,
                column
            );

            isEndGame = checkEndGame(nextSnakePosition, [...state.wallPosition, ...state.toxicFoodPosition]);

            if (!isEndGame) {
                let foodIsEaten = checkFoodIsEaten(nextSnakePosition, state.foodPosition);
                if (foodIsEaten) {
                    nextSnakePosition.push(state.snakePosition[state.snakePosition.length - 1]);
                    newFoodPosition = [
                        createNewFoodPosition(
                            nextSnakePosition,
                            [...state.wallPosition, ...state.toxicFoodPosition],
                            row,
                            column
                        ),
                    ];

                    state.snakeDirection = nextDirection
                    state.snakePosition = nextSnakePosition
                    state.foodPosition = newFoodPosition
                    state.gamePlayerScore = calculateClassModeGameScorePerFood(
                        state.gamePlayerScore,
                        state.gamePlayerLevel,
                        state.gameDuration
                    )
                    state.gamePlayerFood += 1 // add food
                    state.gamePlayerLevel = calculateLevelUnderCertainNumberOfFood(state.gamePlayerFood)
                } else {
                    newClearPosition = [state.snakePosition[state.snakePosition.length - 1]]
                    state.snakeDirection = nextDirection
                    state.snakePosition = nextSnakePosition
                    state.clearPosition = newClearPosition
                }
            } else {
                state.gameIsEnd = true
            }
        },
        gameSurvivalCycle(state, action: PayloadAction<{ snakeNewDirection: Direction, row: number, column: number, difficulty: Level }>) {
            const { snakeNewDirection, row, column, difficulty } = action.payload

            let nextDirection: Direction;
            let nextSnakePosition: Coordinate[];
            let newClearPosition: Coordinate[];
            let isEndGame: boolean;

            if (state.snakeDirection !== null) {
                nextDirection = checkValidDirection(state.snakeDirection, snakeNewDirection)
                    ? snakeNewDirection
                    : state.snakeDirection;
            } else {
                nextDirection = snakeNewDirection
            }
            nextSnakePosition = calculateNextSnakePosition(
                state.snakePosition,
                nextDirection,
                row,
                column
            );

            isEndGame = checkEndGame(nextSnakePosition, [...state.wallPosition, ...state.toxicFoodPosition]);

            if (!isEndGame) {
                if (state.gamePlayerLevel !== calculateLevelUnderCertainTime(state.gameDuration)) {
                    state.gamePlayerLevel = calculateLevelUnderCertainTime(state.gameDuration)
                    newClearPosition = [state.snakePosition[state.snakePosition.length - 1]]
                    state.snakeDirection = nextDirection
                    state.snakePosition = nextSnakePosition
                    state.clearPosition = newClearPosition
                    state.toxicFoodPosition = [...state.toxicFoodPosition, ...Array(survivalToxicCreationWithDifficultyLevel[difficulty]).fill(0).map(() => createRandomCoordinate(row, column, [...state.snakePosition, ...state.toxicFoodPosition, ...state.wallPosition]))]
                } else {
                    newClearPosition = [state.snakePosition[state.snakePosition.length - 1]]
                    state.snakeDirection = nextDirection
                    state.snakePosition = nextSnakePosition
                    state.clearPosition = newClearPosition
                }
                state.wallPosition = createWallPosition(Number(state.gameDuration.toFixed()) / 10000, row, column)
            } else {
                state.gameIsEnd = true
            }
        },
        updateGameDuration(state, action: PayloadAction<{ gameDuration: number }>) {
            state.gameDuration = action.payload.gameDuration
        },
        endGame(state, action: PayloadAction<{ gameIsEnd: boolean, }>) {
            state.gameIsEnd = action.payload.gameIsEnd
        },
        playPauseGame(state, action: PayloadAction) {
            state.gameIsPause = !{ ...state }.gameIsPause
        },
        updateFramerate(state, action: PayloadAction<{ framerate: number }>) {
            state.framerate = action.payload.framerate
        },
        resetFramerate(state, action: PayloadAction) {
            state.framerate = initialState.framerate
        }
    }
})

export const { actions: snakeGameAction } = slice

export const useSnakeGameSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer })
    return { actions: slice.actions }
}