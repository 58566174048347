import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectorGameDuration } from '../../../redux/gameReducer/selectors';
import { timeFormat } from '../../../utils/moment';

interface DurationComponentProps {
  className?: string
}

function DurationComponent({ className }: DurationComponentProps) {

  const gameDuration = useSelector(makeSelectorGameDuration())


  return <div className={className}>Time: {timeFormat(gameDuration)}</div>;
}

export default DurationComponent;
