import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { makeSelectorGameIsEnd, makeSelectorGameIsStart } from '../../../redux/gameReducer/selectors';
import AbsoluteCenter from '../../components/AbsoluteCenter';
import GameOverButton from '../../components/GameButton/GameOverButton';
import GameStartButton from '../../components/GameButton/GameStartButton';

function GameStatusPlatform() {
    const gameIsEnd = useSelector(makeSelectorGameIsEnd())
    const gameIsStart = useSelector(makeSelectorGameIsStart())

    return <>
        <AbsoluteCenter hidden={gameIsStart}>
            <StyledGameStartButton />
        </AbsoluteCenter>
        <AbsoluteCenter hidden={!gameIsEnd}>
            <StyledGameOverButton />
        </AbsoluteCenter>
    </>;
}

export default GameStatusPlatform;

const StyledGameOverButton = styled(GameOverButton)``
const StyledGameStartButton = styled(GameStartButton)``

