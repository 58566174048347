import * as React from 'react'
import { darkTheme, lightTheme } from '../../../config/gameStyleConfig'
interface ICanvasRectStyle {
    fillColor: string,
    strokeColor: string
}
interface ISnakeRecStyle {
    fillColor: string[],
    strokeColor: string
}

interface IBackgroundStyle {
    fillColor: (isTransparent: boolean) => string
    strokeColor: string
}
interface IThemeStyle {
    snake: ISnakeRecStyle,
    food: ICanvasRectStyle,
    background: IBackgroundStyle,
    toxicFood: ICanvasRectStyle
    wall: ICanvasRectStyle
}

const STYLE_THEME_DEFAULT_CONTEXT_VALUE = {
    themeStyle: darkTheme,
    switchTheme: () => { },
    isTransparentMode: false,
    setIsTransparentMode: () => { }
}

interface StyleThemeContextProps {
    themeStyle: IThemeStyle
    switchTheme: () => void
    isTransparentMode: boolean
    setIsTransparentMode: (key: boolean) => void
}

export const StyleThemeContext = React.createContext<StyleThemeContextProps>(STYLE_THEME_DEFAULT_CONTEXT_VALUE)

export const useStyleTheme = () => React.useContext(StyleThemeContext)

interface StyleThemeProviderProps {
    children: React.ReactChild
}

function StyleThemeProvider({ children }: StyleThemeProviderProps) {
    const [isLight, setIsLight] = React.useState<boolean>(false) // true mean light false mean dark
    const [themeStyle, setThemeStyle] = React.useState<IThemeStyle>(lightTheme)
    const [isTransparentMode, setIsTransparentMode] = React.useState<boolean>(false)

    const switchTheme = () => {
        setIsLight(prev => !prev)
    }

    React.useEffect(() => {
        switch (isLight) {
            case true: {
                setThemeStyle(lightTheme)
                break
            }
            case false: {
                setThemeStyle(darkTheme)
                break
            }
        }
    }, [isLight])

    return <StyleThemeContext.Provider value={{ themeStyle, switchTheme, isTransparentMode, setIsTransparentMode }}>
        {children}
    </StyleThemeContext.Provider>
}

export default StyleThemeProvider