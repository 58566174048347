import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectorGamePlayerLevel } from '../../../redux/gameReducer/selectors';

interface LevelComponentProps {
    className?: string
}

function LevelComponent({ className }: LevelComponentProps) {

    const level = useSelector(makeSelectorGamePlayerLevel())

    return <div className={className}>Level: {level}</div>;
}

export default LevelComponent;
