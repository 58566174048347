import * as React from 'react'

const DEFAULT_ROUTE_CONTEXT = {
    currentPath: "/",
    changePath: () => { }
}

interface RouteContextProps {
    currentPath: string,
    changePath: (path: string) => void
}

export const RouteContext = React.createContext<RouteContextProps>(DEFAULT_ROUTE_CONTEXT)

export const useRouteContext = () => React.useContext(RouteContext)

interface RouteContextProviderProps {
    children: React.ReactChild
}

export const RouteContextProvider = ({ children }: RouteContextProviderProps) => {
    const [currentPath, setCurrentPath] = React.useState<string>(window.location.pathname)

    const changeCurrentPath = (path: string) => {
        setCurrentPath(path)
    }

    return <RouteContext.Provider value={{
        currentPath: currentPath,
        changePath: changeCurrentPath
    }}>
        {children}
    </RouteContext.Provider>

}