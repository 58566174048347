import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { ResponsiveProvider } from "./app/provides/ResponsiveProvider/ResponsiveProvider";
import { Provider } from "react-redux";
import { configureAppStore } from "./store/configureStore";
import { RouteContextProvider } from "./app/provides/RouteProvider/RouteProvider";

console.log("v0.1.0");

const store = configureAppStore();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouteContextProvider>
        <ResponsiveProvider>
          <App />
        </ResponsiveProvider>
      </RouteContextProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
