import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { Direction } from '../type/gameType';
import useKeyPress from './useKeyPress';

function useDirection() {
    const [snakeDirection, setSnakeDirection] = React.useState<Direction | null>(null)
    const [onTouchValue, setOnTouchValue] = React.useState<{ deltaX: number, deltaY: number }>({ deltaX: 0, deltaY: 0 })

    const { keyPressed: upPress, setKeyPressed: setUpPress } = useKeyPress("ArrowUp");
    const { keyPressed: downPress, setKeyPressed: setDownPress } = useKeyPress("ArrowDown");
    const { keyPressed: rightPress, setKeyPressed: setRightPress } = useKeyPress("ArrowRight");
    const { keyPressed: leftPress, setKeyPressed: setLeftPress } = useKeyPress("ArrowLeft");

    const resetDirection = () => {
        setSnakeDirection(null)
        setOnTouchValue({ deltaX: 0, deltaY: 0 })
        setLeftPress(false)
        setUpPress(false)
        setRightPress(false)
        setDownPress(false)
    }

    const moveUpward = () => {
        setSnakeDirection(Direction.UP)
        setDownPress(false)
        setRightPress(false)
        setLeftPress(false)
    }

    const moveDownward = () => {
        setSnakeDirection(Direction.DOWN)
        setUpPress(false)
        setRightPress(false)
        setLeftPress(false)
    }
    const moveLeft = () => {
        setSnakeDirection(Direction.LEFT)
        setUpPress(false)
        setRightPress(false)
        setDownPress(false)
    }
    const moveRight = () => {
        setSnakeDirection(Direction.RIGHT)
        setUpPress(false)
        setDownPress(false)
        setLeftPress(false)
    }

    const handlers = useSwipeable({
        // onSwipedLeft: () => {
        //     if (snakeDirection === Direction.RIGHT) return
        //     moveLeft()
        // },
        // onSwipedRight: () => {
        //     if (snakeDirection === Direction.LEFT) return
        //     moveRight()
        // },
        // onSwipedUp: () => {
        //     if (snakeDirection === Direction.DOWN) return
        //     moveUpward()
        // },
        // onSwipedDown: () => {
        //     if (snakeDirection === Direction.UP) return
        //     moveDownward()
        // },
        onSwiped: (data) => {
            setOnTouchValue({ deltaX: 0, deltaY: 0 })
        },
        onSwiping: (data) => {
            const threshold = 50
            const { deltaX: prevX, deltaY: prevY } = onTouchValue
            const { deltaX: currX, deltaY: currY } = data

            if (currX - prevX > threshold) {
                moveRight()
                setOnTouchValue({ deltaX: currX, deltaY: currY })
                return
            }
            if (currX - prevX < -threshold) {
                moveLeft()
                setOnTouchValue({ deltaX: currX, deltaY: currY })
                return
            }
            if (currY - prevY > threshold) {
                moveDownward()
                setOnTouchValue({ deltaX: currX, deltaY: currY })
                return
            }
            if (currY - prevY < -threshold) {
                moveUpward()
                setOnTouchValue({ deltaX: currX, deltaY: currY })
                return
            }
        }
    });


    React.useEffect(() => {
        if (upPress) {
            if (snakeDirection === Direction.DOWN) return
            moveUpward()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [upPress])

    React.useEffect(() => {
        if (downPress) {
            if (snakeDirection === Direction.UP) return
            moveDownward()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [downPress])

    React.useEffect(() => {
        if (rightPress) {
            if (snakeDirection === Direction.LEFT) return
            moveRight()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [rightPress])

    React.useEffect(() => {
        if (leftPress) {
            if (snakeDirection === Direction.RIGHT) return
            moveLeft()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [leftPress])

    return { handlers, snakeDirection, resetDirection }
}

export default useDirection;
