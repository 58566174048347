import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGameConfigSlice } from '../../../redux/configReducer/reducer';
import { useSnakeGameSlice } from '../../../redux/gameReducer/reducer';
import { makeSelectorFramerate } from '../../../redux/gameReducer/selectors';
import { Level } from '../../../type/gameType';
import { GameConfigContext } from '../../context/GameConfigContext';
import { Button, SwipeableDrawer } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import styled from 'styled-components';
import SwipeablePanel from './components/SwipeablePanel';
import { makeSelectorColumn, makeSelectorDifficulty, makeSelectorRow, makeSelectorSquareWidth } from '../../../redux/configReducer/selectors';
import { globalTheme } from '../../../config/generalStyle';

interface ControlPanelProps {
    className?: string
    disabled?: boolean
}

function ControlPanel({ className, disabled }: ControlPanelProps) {
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const dispatch = useDispatch()
    const { actions: snakeGameActions } = useSnakeGameSlice()
    const { actions: gameConfigActions } = useGameConfigSlice()

    const difficulty = useSelector(makeSelectorDifficulty())
    const squareWidth = useSelector(makeSelectorSquareWidth())
    const row = useSelector(makeSelectorRow())
    const column = useSelector(makeSelectorColumn())
    const framerate = useSelector(makeSelectorFramerate())

    const handleResetClick = () => {
        dispatch(gameConfigActions.resetConfig())
        dispatch(snakeGameActions.resetFramerate())
    }

    const handleChangeSquareWidth = (squareWidth: number) => {
        dispatch(gameConfigActions.updateSquareWidth({ squareWidth }))
    }

    const handleChangeRowColumn = (row: number, column: number) => {
        dispatch(gameConfigActions.updateRowColumn({ row, column }))
    }

    const handleChangeDifficulty = (difficulty: Level) => {
        dispatch(gameConfigActions.updateDifficulty({ difficulty }))
    }

    const handleChangeFramerate = (framerate: number) => {
        dispatch(snakeGameActions.updateFramerate({ framerate }))
    }

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setIsOpen(open)
    }

    return <GameConfigContext.Provider value={{
        handleResetClick,
        handleChangeSquareWidth,
        handleChangeRowColumn,
        handleChangeDifficulty,
        handleChangeFramerate,
        squareWidth,
        row,
        column,
        difficulty,
        framerate
    }}>
        <StyledControlPanelContainer className={className}>
            <Button

                onClick={toggleDrawer(true)}
                disabled={disabled}
            >
                <BuildIcon fontSize="large" sx={{ color: globalTheme.icon.color }} />
            </Button>
            <SwipeableDrawer
                swipeAreaWidth={30}
                anchor="bottom"
                open={isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <SwipeablePanel />
            </SwipeableDrawer>
        </StyledControlPanelContainer>
    </GameConfigContext.Provider>
}

export default ControlPanel;

const StyledControlPanelContainer = styled.div``