import * as React from 'react'
import { Direction } from '../../type/gameType'
import { randomEnum } from '../../utils/game-direction'

export const SNAKE_GAME_DEFAULT_CONTEXT_VALUES: SnakeGameContextValues = {
    gameInit: () => { },
    gameStart: () => { },
    gamePlayPause: () => { },
    runGameCycle: () => { },
    drawAll: () => { },
    draw: () => { },
    changeGameIsEndState: () => { },
    snakeControlDirection: null
}

export interface SnakeGameContextValues {
    gameInit: () => void,
    gameStart: () => void,
    gamePlayPause: () => void,
    runGameCycle: () => void,
    drawAll: () => void,
    draw: () => void,
    changeGameIsEndState: (gameIsEnd: boolean) => void,
    snakeControlDirection: Direction | null
}

export const SnakeGameContext = React.createContext<SnakeGameContextValues>(
    SNAKE_GAME_DEFAULT_CONTEXT_VALUES,
)

export const useSnakeGame = () => React.useContext(SnakeGameContext)
