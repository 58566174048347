import { Coordinate } from "../type/gameType";

export class Canvas {
    static drawGrid(ctx: CanvasRenderingContext2D | null, row: number, column: number, squareWidth: number, fillColor: string, strokeColor: string, xOffset: number = 0, yOffset: number = 0) {
        if (!ctx) return
        for (let x = 0; x < row; x++) {
            for (let y = 0; y < column; y++) {
                ctx.beginPath();
                ctx.strokeStyle = strokeColor
                ctx.fillStyle = fillColor
                ctx.rect(x * squareWidth + xOffset, y * squareWidth + yOffset, squareWidth, squareWidth)
                ctx.fill()
                // ctx.stroke()
            }
        }
    }

    static drawRect(ctx: CanvasRenderingContext2D | null, x: number, y: number, squareWidth: number, fillColor: string, strokeColor: string, xOffset: number = 0, yOffset: number = 0) {
        if (!ctx) return
        ctx.beginPath();
        ctx.strokeStyle = strokeColor
        ctx.fillStyle = fillColor
        ctx.rect(x * squareWidth + xOffset, y * squareWidth + yOffset, squareWidth, squareWidth)
        ctx.fill()
        // ctx.stroke()
    }

    static clearRect(ctx: CanvasRenderingContext2D | null, x: number, y: number, squareWidth: number, xOffset: number = 0, yOffset: number = 0) {
        if (!(ctx)) return
        ctx.clearRect(x * squareWidth + xOffset, y * squareWidth + yOffset, squareWidth, squareWidth);
    }

    static drawRectList(arr: Coordinate[], ctx: CanvasRenderingContext2D | null, x: number, y: number, squareWidth: number, fillColor: string, strokeColor: string, xOffset: number = 0, yOffset: number = 0) {
        arr.forEach(({ x, y }: Coordinate) => {
            this.drawRect(ctx, x, y, squareWidth, fillColor, strokeColor, xOffset, yOffset)
        })
    }

    static clearRectList(arr: Coordinate[], ctx: CanvasRenderingContext2D | null, x: number, y: number, squareWidth: number, xOffset: number = 0, yOffset: number = 0) {
        if (!(ctx)) return
        arr.forEach(({ x, y }: Coordinate) => {
            this.clearRect(ctx, x, y, squareWidth, xOffset, yOffset)
        })
    }

    static clearCanvasGrid(ctx: CanvasRenderingContext2D | null, canvas: HTMLCanvasElement | null) {
        if (!(ctx && canvas)) return
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
}