// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { decode } from "./utils/base64";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export const app = initializeApp(
  JSON.parse(decode(process.env.REACT_APP_FIREBASE_CONFIG || ""))
);
