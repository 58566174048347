import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Stack } from '@mui/material';
import styled from 'styled-components';
import { globalTheme } from '../../config/generalStyle';

function DirectionAnimationComponent() {
    return <StyledArrowContainer >
        <Stack direction="row" justifyContent="center">
            <ArrowUpwardIcon className='arrow-icon up' />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
            <ArrowBackIcon className='arrow-icon back' />
            <ArrowForwardIcon className='arrow-icon forward' />
        </Stack>
        <Stack direction="row" justifyContent="center">
            <ArrowDownwardIcon className='arrow-icon down' />
        </Stack>
    </StyledArrowContainer>;
}

export default DirectionAnimationComponent;

const StyledArrowContainer = styled.div`
    padding:0px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:250px;
    height:250px;

    .arrow-icon{
        font-size:5em;
        position:relative;
        color:#717171;
    }

    .up{
        animation-name: move-upward ;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        @keyframes move-upward {
        0%   {color:#717171; top:0px;}
        50%  {color:${globalTheme.text.color}; top:-20px;}
        100% {color:#717171; top:0px;}
        }
    }
    .down{
        animation-name: move-downward ;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        @keyframes move-downward{
        0%   {color:#717171; bottom:0px;}
        50%  {color:${globalTheme.text.color}; bottom:-20px;}
        100% {color:#717171; bottom:0px;}
        }
    }
    .back{
        animation-name: move-backward ;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        @keyframes move-backward{
        0%   {color:#717171;  left:0px;}
        50%  {color:${globalTheme.text.color}; left:-20px;}
        100% {color:#717171; left:0px;}
        }
    }
    .forward{
        animation-name: move-forward ;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        @keyframes move-forward{
        0%   { color:#717171; right:0px;}
        50%  {color:${globalTheme.text.color}; right:-20px;}
        100% {color:#717171;right:0px;}
        }
    }
`