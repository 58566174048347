export const globalTheme = {
    text: {
        color: "#C3C3C3"
    },
    background: {
        color: "#191919"
    },
    gameTitleBackground: {
        color: "#131516"
    },
    headerBackground: {
        color: "#111111"
    },
    buttonText: {
        color: "#C3C3C3"
    },
    icon: {
        color: "#C3C3C3"
    }
}